.create-task {
  display: grid;
  grid-template-columns: 100%;
  gap: 10px;
}
.create-task .span-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
