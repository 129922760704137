.lead-form-search {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  width: 100%;
}

.lead-form-datetime {
  display: flex;
  flex-direction: column;
}

.lead-form-datetime span.action-search {
  cursor: pointer;
}

.lead-form-datetime span.active-search {
  color: #2acaea;
}
