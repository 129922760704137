.ranking {
  display: flex;
  gap: 20px;
  justify-content: space-evenly;
	margin-top:30px;
}
.ranking .form-search{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap:10px;
}
.ranking .rank-exp,
.ranking .rank-revenue {
}
.ranking .rank-item {
  position: relative;
  padding: 5px 10px;
  border-radius: 5px;
}

.ranking .rank-item:hover {
  background-color: #cdcdcd;
}

.ranking .rank-item-content {
  display: flex;
  gap: 20px;
  padding-left: 50px;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px 10px 5px 50px;
}

.rank-list .rank-item:nth-child(1) {
  font-weight: 700;
  font-size: 24px;
}

.rank-list .rank-item:nth-child(2) {
  font-weight: 700;
  font-size: 20px;
}

.rank-list .rank-item:nth-child(3) {
  font-weight: 700;
  font-size: 18px;
}

.ranking .rank-number {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translate(-50%, -50%);
}
.rank-list .rank-item:nth-child(1) .rank-number,
.rank-list .rank-item:nth-child(2) .rank-number,
.rank-list .rank-item:nth-child(3) .rank-number {
  width: 26px;
  height: 26px;
  color: transparent;
  background-size: cover;
}
.rank-list .rank-item:nth-child(1) .rank-number {
  background-image: url('./img/rank-1.svg');
}

.rank-list .rank-item:nth-child(2) .rank-number {
  background-image: url('./img/rank-2.svg');
}

.rank-list .rank-item:nth-child(3) .rank-number {
  background-image: url('./img/rank-3.svg');
}

.ranking-agent-info > div {
  display: flex;
  gap: 10px;
}
.ranking-agent-info > div > span:first-child {
  font-weight: 700;
}
.ranking-agent-info span {
  font-size: 16px;
}

