table{
 border-collapse: collapse;
}
.tbl-import-result td, .tbl-import-result th{
	border:1px solid #111a;
	padding:0 10px
}

.tbl-import-result td{
	text-align:center;
}
.button-container{
	margin:20px 0;
	display: flex;
	justify-content: flex-end;
	column-gap: 20px;
	align-items:center
}
.import-result{
	display:flex;
	flex-wrap: wrap;
	column-gap: 30px;
	margin: 20px
}
