.media_manage{
	padding-left: 4px;
	border-left:1px solid rgba(5,5,5,0.5);
	height:100vh;
}
.media_item{
	position:relative;
}

.media_item:hover .media_action{
opacity:1;
}

.media_action{
	opacity:0;
	position: absolute;
	bottom:0;
	left:0;
	right:0;
	top:0;
	background:#f1f1f1;
	transition: opacity ease-in-out 0.3s;
	display:flex;
	justify-content:center;
	align-items:center;
}

.media_item_video{
	width:143px;
	height:143px;
	display:flex;
	justify-content:center;
	align-items:center
}


.media_item_video{
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center
}

.thumbnail{
	width:100%;
	min-height:100px;
	background-color:#fff;
	display:flex;
	justify-content:center;
	align-items:center;
}

.thumbnail img{
	width:100%;
}
