.revenue-kpi-form-search {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  width: 100%;
}

.form-create-revenue-kpi {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.revenue-kpi .button-search {
  display: flex;
  justify-content: flex-start;
	gap:10px;
	margin-top: 10px;
}
