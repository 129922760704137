.moniter {
  margin-top: 20px;
}

table.moniter-list {
  width: 100%;
}
table.moniter-list,
.moniter-list th,
.moniter-list td {
  border: 1px solid #ccc;
  border-collapse: collapse;
  padding: 4px;
}

.moniter-list th {
  background: #efefef;
}

.moniter-list td:first-child {
  text-align: center;
}

.moniter .btn-add {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0px;
}

.moniter .title {
  display: flex;
  justify-content: space-between;
}
