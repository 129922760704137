.report-business {
}
.report-business-form-search {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
	max-width: 800px;
	width: 100%;
	margin: 0 auto;
  grid-gap: 10px;
}
