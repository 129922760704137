.timetable {
  display: block;
	padding-bottom: 90px;
	margin-top: 30px;
}

.timetable .form-search {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  align-items: end;
  max-width: 50%;
  margin: 0 auto;
}
.timetable .select-type-searchdate {
  grid-column: span 2 / span 2;
}

.timetable .search-date {
  grid-column: span 2 / span 2;
}

.timetable .form-search .date {
  grid-column: span 2 / span 2;
}

.timetable table {
  width: 100%;
}
.timetable th,
.timetable td {
  text-align: center;
  border: 1px solid #ccc;
}
.timetable td:first-child,
.timetable td:last-child,
.timetable th:first-child,
.timetable th:last-child {
  border-left: none;
}
.timetable td {
  padding: 5px 0;
}
.timetable th {
  background-color: #eae9ed;
}
.timetable .timetable-list {
  display: grid;
  column-gap: 30px;
  row-gap: 20px;
	margin-bottom: 90px;
}
.timetable .timetable-item {
  margin-bottom: 35px;
}
.timetable .agent-name {
  background-color: green;
  padding: 5px 10px;
  color: #fff;
  margin-bottom: 0;
  font-size: 16px;
}

.timetable .task-item {
}
.timetable .task-title {
  background-color: #046060;
  color: #fff;
  padding: 5px 10px;
  font-weight: 700;
}
.timetable .task-list {
  max-height: 600px;
	min-height:90px;
  height: 100%;
  border: 1px solid #ccc;
  overflow-y: scroll;
  position: relative;
}
.timetable .task-item-time {
  width: 120px;
}
.timetable .task-item-status,
.timetable .task-item-priority {
  width: 15%;
}

.timetable .task-item-summary {
  flex: 1;
  cursor: pointer;
}
.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.timetable .task-item-title {
  width: 130px;
}
.timetable .pagination {
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.timetable .no-data {
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #ed2b2a;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
