.no-data {
  display: flex;
  min-height: 100px;
  flex-flow: column;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
}

.no-data img {
  width: 100px;
}

.no-data h1 {
  color: #cfcfcf;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
}
