.post_item{
	width:100%;
	position:relative;
	background-color:#fff;
	border-radius:20px;
}

.post_lock{
	position:absolute;
	top:10px;
	left:10px;
	width:45px;
	height:45px;
	display:flex;
	justify-content:center;
	align-items:center;
	background-color:rgba(255, 255, 255, .4);
	border-radius:50%;
}

.post_thumbnail{
	width:100%;
}

.post_thumbnail img{
	width:100%;
	height:auto;
	border-radius:20px;
}


.post_item:hover .post_info>.post_action{
	opacity:1;
	height:40px
}

.post_info{
	position:absolute;
	bottom:00px;
	left:0;
	right:0;
	transition: bottom ease-in-out 0.3s;
	background-color:#fff;
	padding:10px 20px;
	border-bottom-left-radius:20px;
	border-bottom-right-radius:20px;
}
.post_title{
	width:100%;
	font-size:24px;
	font-weight:700
}

.post_action{
	width:100%;
	height:0px;
	opacity: 0;
	transition:all ease-in-out 0.3s;
}
