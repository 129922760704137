:root {
  --primary-bg: #046060;
  --primary-border: #ccc;
}
.work-management table .status {
  border-radius: 4px;
  color: #fff;
  padding: 4px 5px;
}

.work-management .actions {
}
.work-management .form-search {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  margin: 20px 0;
}
.work-management .pagination {
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.work-management span.table-cell-head {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.work-management .btn-search {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
	padding-bottom: 10px
}
/* .work-management select { */
/*   outline: none; */
/*   min-width: 200px; */
/*   border: 1px solid var(--primary-bg); */
/* } */
/* .work-management select{ */
/*   padding: 3px 4px; */
/* } */
/* .work-management input { */
/*   padding: 4px; */
/* } */
