.task-detail .task-info {
  /* display: flex; */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.task-detail .task-desc {
  font-size: 18px;
}

.task-detail .task-info .task-info-item-head {
  background-color: var(--primary-bg);
  color: #fff;
  padding: 0px 10px;
  width: 100%;
}
.task-overview {
  display: flex;
  gap: 20px;
}
.task-overview .base-info {
  flex: 1;
}
.task-overview .actions {
  margin-top: 30px;
}
table.task-info-item {
  width: 100%;
}
table.task-info-item,
.task-info-item th,
.task-info-item td {
  border: 1px solid #ccc;
  border-collapse: collapse;
}
table.task-info-item tr td:first-child {
  width: 200px;
}
.task-info-item th {
  padding: 8px 0px;
  font-size: 16px;
}
.task-info-item td {
  padding: 5px 10px;
  min-height: 30px;
}

.task-detail .color {
  width: 20px;
  height: 15px;
  border-radius: 4px;
  float: right;
}

.other-info {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}

.form-new-comment {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.form-new-comment div {
  display: flex;
  flex-flow: column;
  gap: 5px;
  flex: 1;
}
.form-new-comment .cancel-edit-comment {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}
.task-detail .title {
  background-color: var(--primary-bg);
  color: #fff;
  padding: 8px 10px;
  margin: 0px;
  font-size: 16px;
}
.task-detail .title svg {
  margin-left: 20px;
  cursor: pointer;
}

.task-activiti {
  margin-top: 20px;
}
.task-activiti table {
}

.task-activiti table {
  width: 100%;
}
.task-activiti table,
.task-activiti th,
.task-activiti td {
  border: 1px solid #ccc;
  border-collapse: collapse;
}

.task-activiti th {
  padding: 8px 0px;
  font-size: 16px;
  background-color: #efefef;
}
.task-activiti td {
  padding: 5px 10px;
  min-height: 30px;
}
.task-activiti td:first-child {
  text-align: center;
}

.task-activiti tr:nth-child(even) {
  background-color: #efefef;
}

.task-activiti tr:hover {
  background-color: #efefef;
}
