.send-email {
  display: flex;
  gap: 20px;
}
.send-email > div {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 2px 3px 4px rbga(0, 0, 0, 0.1);
}
