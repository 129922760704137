.report-date-search {
  display: flex;
  gap: 20px;
  justify-content: space-evenly;
}
.report-date-form {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  width: 500px;
}

.report-date-form-export {
  display: grid;
  grid-template-columns: 1fr;
	gap:10px
}
