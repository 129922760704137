.btn-container{
	display: flex;
	width: 100%;
	justify-content: flex-end;
	gap: 10px;
	margin-top: 20px;
}

.input-container{
	display: flex;
	gap:4px;
	align-items: center
}

.input-container div{
	display: flex;
	gap:10px;
	flex-wrap: nowrap;
}
.update-agent-container{
	display: grid;
	gap: 10px;
}
