.comment {
  margin-top: 20px;
}
.comment h3 {
  margin: 0px;
}
table.comment-list {
  width: 100%;
}
table.comment-list,
.comment-lis th,
.comment-lis td {
  border: 1px solid #ccc;
  border-collapse: collapse;
}

.comment-item-action {
  gap: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.comment-item-action svg {
  cursor: pointer;
}

.comment-item .comment-content {
  display: flex;
  flex-flow: column;
}

.comment-item:hover {
  background-color: #efefef;
}

.comment-item:nth-child(even) {
  background-color: #efefef;
}
.comment-author {
  display: flex;
  gap: 10px;
}

.cmt-reply {
  padding-left: 40px;
  position: relative;
}

.cmt-reply::after {
  content: '';
  width: 20px;
  height: 35px;
  position: absolute;
  top: 0px;
  left: 10px;
  border-left: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  border-bottom-left-radius: 40%;
}

/* .cmt-reply::before{ */
/* 	content:""; */
/* 	width: 20px; */
/* 	height: 2px; */
/* 	position: absolute; */
/* 	bottom:3px; */
/* 	left: 20px; */
/* 	background-color: #000; */
/* } */
/**/
/**/
